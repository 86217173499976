import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getCarousel() {
    return this.http.get(`${this.baseUrl}/collections/get/carousel`);
  }
  getFeatured() {
    return this.http.get(`${this.baseUrl}/collections/get/featured`);
  }
  getProductDescription() {
    return this.http.get(`${this.baseUrl}/singletons/get/products`);
  }
}
