import {Component, OnInit} from '@angular/core';
import {AppService} from '../app.service';
import {Carousel} from './carousel';
import {DomSanitizer} from '@angular/platform-browser';
import {Featured} from './featured';


declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  carousel: any;
  featured: any;
  descriptions: any;
  productDescription: any;

  constructor(private appSerive: AppService, private sanitizer: DomSanitizer) {

  }

  ngOnInit() {


    this.appSerive.getCarousel().subscribe((res: Carousel[]) => {
      this.carousel = res.entries;
    });
    this.appSerive.getFeatured().subscribe((res: Featured[]) => {
      this.featured = res.entries;
    });
    this.appSerive.getProductDescription().subscribe(res => {
      this.productDescription = res;
    });

    setTimeout(() => {
        this.activate();
      },
      300);


  }

  activate() {
    $(window).stellar({
      responsive: true,
      parallaxBackgrounds: true,
      parallaxElements: true,
      horizontalScrolling: false,
      hideDistantElements: false,
      scrollProperty: 'scroll'
    });


    const fullHeight = () => {

      $('.js-fullheight').css('height', $(window).height());
      $(window).resize(() => {
        $('.js-fullheight').css('height', $(window).height());
      });

    };
    fullHeight();

    // loader
    const loader = () => {
      setTimeout(() => {
        if ($('#ftco-loader').length > 0) {
          $('#ftco-loader').removeClass('show');
        }
      }, 1);
    };
    loader();

    // Scrollax
    $.Scrollax();

    const carousel = () => {
      $('.home-slider').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 0,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        nav: false,
        autoplayHoverPause: false,
        items: 1,
        navText: ['<span class=\'ion-md-arrow-back\'></span>', '<span class=\'ion-chevron-right\'></span>'],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
      $('.carousel-testimony').owlCarousel({
        center: true,
        loop: true,
        items: 1,
        margin: 30,
        stagePadding: 0,
        nav: false,
        navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1000: {
            items: 3
          }
        }
      });
      $('.carousel-client').owlCarousel({
        center: true,
        loop: true,
        items: 4,
        margin: 30,
        stagePadding: 0,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: false,
        rewindNav: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1000: {
            items: 5
          }
        }
      });

      $('.single-slider').owlCarousel({
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplay: true,
        loop: true,
        items: 1,
        margin: 0,
        stagePadding: 0,
        nav: true,
        dots: true,
        navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });

    };
    carousel();

    // scroll
    var scrollWindow = function() {
      $(window).scroll(function() {
        var $w = $(this),
          st = $w.scrollTop(),
          navbar = $('.ftco_navbar'),
          sd = $('.js-scroll-wrap');

        if (st > 150) {
          if (!navbar.hasClass('scrolled')) {
            navbar.addClass('scrolled');
          }
        }
        if (st < 150) {
          if (navbar.hasClass('scrolled')) {
            navbar.removeClass('scrolled sleep');
          }
        }
        if (st > 350) {
          if (!navbar.hasClass('awake')) {
            navbar.addClass('awake');
          }

          if (sd.length > 0) {
            sd.addClass('sleep');
          }
        }
        if (st < 350) {
          if (navbar.hasClass('awake')) {
            navbar.removeClass('awake');
            navbar.addClass('sleep');
          }
          if (sd.length > 0) {
            sd.removeClass('sleep');
          }
        }
      });
    };
    scrollWindow();


    var counter = function() {

      $('#section-counter').waypoint(function(direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',');
          $('.number').each(function() {
            var $this = $(this),
              num = $this.data('number');
            console.log(num);
            $this.animateNumber(
              {
                number: num,
                numberStep: comma_separator_number_step
              }, 7000
            );
          });

        }

      }, {offset: '95%'});

    };
    counter();

    var contentWayPoint = function() {
      var i = 0;
      $('.ftco-animate').waypoint(function(direction) {

        if (direction === 'down' && !$(this.element).hasClass('ftco-animated')) {

          i++;

          $(this.element).addClass('item-animate');
          setTimeout(function() {

            $('body .ftco-animate.item-animate').each(function(k) {
              var el = $(this);
              setTimeout(function() {
                var effect = el.data('animate-effect');
                if (effect === 'fadeIn') {
                  el.addClass('fadeIn ftco-animated');
                } else if (effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft ftco-animated');
                } else if (effect === 'fadeInRight') {
                  el.addClass('fadeInRight ftco-animated');
                } else {
                  el.addClass('fadeInUp ftco-animated');
                }
                el.removeClass('item-animate');
              }, k * 50, 'easeInOutExpo');
            });

          }, 100);

        }

      }, {offset: '95%'});
    };
    contentWayPoint();
  }

  setInfo(info: string) {
    this.descriptions = info;
  }

}
