import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  hash;

  constructor() {
  }

  ngOnInit() {
    // navigation
    const OnePageNav = () => {
      $('.smoothscroll[href^=\'#\'], #ftco-nav ul li a[href^=\'#\']').on('click', (e) => {
        e.preventDefault();

        const hash = this.hash,
          navToggler = $('.navbar-toggler');
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 200, 'easeInOutExpo', () => {
          window.location.hash = hash;
        });


        if (navToggler.is(':visible')) {
          navToggler.click();
        }
      });
      $('body').on('activate.bs.scrollspy', () => {
        console.log('nice');
      });
    };
    OnePageNav();

  }

}
